import React from "react";
 
function ContactUs() {
  return (
    <div className="contact-us-container">
      <div className="contact-content">
        <h2>Contact Us</h2>
        <p>
          For inquiries or assistance, please email us at{" "}
          <a href="mailto:contact@example.com">info@egopie.co</a>
        </p>
		<p> Alternatively, give us a call: +234(0)8067262985</p>
        <p>Our office hours are Monday to Friday, 9:00 AM - 5:00 PM.</p>
      </div>
    </div>
  );
}

export default ContactUs;
