import React, { useEffect, useRef } from 'react';
import MainProduct1 from "../assets/images/egopie-eye-main-bg.jpg"
import MainProduct2 from "../assets/images/product2.jpg"
import MainProduct3 from "../assets/images/pilot-main-bg.jpeg"
import Product1 from "../assets/images/egoie-eye-list-bg.jpeg"
import Product2 from "../assets/images/laundry-sm-bg.jpeg"
import Product3 from "../assets/images/pilot-sm-bg.jpeg"
import product1Logo from "../assets/images/egopie-eye.png"
import product2Logo from "../assets/images/egopie-laundry-logo.png"
import product3Logo from "../assets/images/pilot-logo.png"
import Header from './Header2';
import Footer from './Footer';
const ProductCard = () => {
    const products = [
        {
            id: 1,
            name: 'EGOPIE EYES ',
            image: MainProduct1,
            listImage: Product1,
            productLogo: product1Logo,
            logoDesc: "Intelligent Road and Traffic Surveillance Systems",
            description: 'EYES prides itself as the leading Intelligent Road and Traffic Surveillance company in Africa. Birthed during the latter technological years of the 21st Century, its Intelligent Road and Traffic Surveillance technologies are up to date. At EGOPIE EYES, we provide fully integrated customer solutions for IoT road and traffic security surveillance. By using the very best of Artificial Intelligence (A.I) capabilities, we provide unparalled road and traffic surveillance for governments and their citizens.',
            webURL: "http://www.Egopieeyes.com",
            contactUs: "mailto:info@egopieeyes.com",
        },
        {
            id: 2,
            name: 'EGOPIE Laundry',
            image: MainProduct2,
            listImage: Product2,
            productLogo: product2Logo,
            logoDesc: "Fully Digital Self-Service Laundromats",
            description: 'At the laundry, we specialise in providing fully digital self-service laundromats to campuses and communal living areas across Nigeria and Africa. We fulfil this obligation by offering smart laundry solutions through our proprietary systems and world class state-of-the-art washing and drying machines. We are all about giving you more time to spend them doing the things that matter most. Therefore, we take pride in using technology and innovation to make doing laundry efficient and timely.',
            webURL: "http://www.Egopielaundry.com",
            contactUs: "mailto:info@egopielaundry.com",
        },
        {
            id: 3,
            name: 'EGOPIE Pilot',
            image: MainProduct3,
            listImage: Product3,
            productLogo: product3Logo,
            logoDesc: "Your Destination is Our Priority",
            description: 'Coming soon....',
            webURL: "http://www.egopiepilot.com",
            contactUs: "mailto:info@egopiepilot.com",
        }
    ];

    const productRefs = useRef([]);
    const handleButtonClick = (productId) => {
        const productRef = productRefs.current[productId - 1];
        if (productRef) {
            productRef.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const parentDivRef = useRef();

    const scrollToTop = () => {
        if (parentDivRef.current) {
            parentDivRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };
    useEffect(() => {
        // Scroll to top when the component mounts or when the scroll snapping state changes
        scrollToTop2();
    }, (200)); // Only run this effect once when the component mounts

    const scrollToTop2 = () => {
        if (parentDivRef.current) {
            parentDivRef.current.scrollTop = 0;
        }
    };
    return (
        <>
            <Header />
            <div className="product-wrapper2" ref={parentDivRef}>
                <div className='product-list-wrapper animation'>
                    <div className='container text-white '>
                        <h1 className='product-heading text-center mb-5'>Choose Your Destination</h1>
                        <ul className='list-product list-unstyled d-flex justify-content-center'>
                            {products.map(product => (
                                <li key={product.id}>
                                    <div className='product-img position-relative' onClick={() => handleButtonClick(product.id)}>
                                        <img src={product.listImage} alt='product img' className='img-fluid' />
                                    </div>
                                    <p className='slogan'>{product.logoDesc}</p>
                                    <h3>{product.name}</h3>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {products.map((product) => (
                    <div key={product.id}
                        ref={(ref) => {
                            productRefs.current[product.id - 1] = ref;
                        }}
                        className={`product-section text-black text-center position-relative ${product.id === 2 || product.id === 3 ? 'text-white' : 'text-white'}`}>
                        <div className="product-bg pt-5">
                            <img className="img-fluid w-100 h-100" src={product.image} alt='product img' />
                        </div>
                        <div className='content-wrapper'>
                            {/*<h3 className='mb-4'>{product.name}</h3>*/}
                            <div className='product-logo mx-auto'>
                                <img className='img-fluid' src={product.productLogo} alt="product logo" />
                            </div>
                            <div className='mb-sm-5 mb-4 mt-4 d-flex align-items-center justify-content-center'>
                                <p className='slogan mb-0'>{product.logoDesc}</p>
                            </div>
                            <p className='mb-4'>
                                {product.description}
                            </p>
                        </div>
                        <div className='btn-wrapper'>
                            <a href={product.webURL} target="_blank" className='button'>Visit Now</a>
                            <a href={product.contactUs} className='button'>Contact Us</a>
                        </div>
                    </div>
                ))}
                <Footer scrollToTopCallback={scrollToTop} />
            </div>
        </>
    );
};

export default ProductCard;