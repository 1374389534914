import React, { useEffect, useRef } from 'react';
import ContactSupport from "../assets/images/account.svg"
import ChatWithUs from "../assets/images/abuse.svg"
import ContactSales from "../assets/images/sales.svg"
import Header from './Header2';
import Footer from './Footer';
const Contacts = () => {
    const contact = [
        {
            id: 1,
            name: 'Contact Support ',
            image: ContactSupport,
            listImage: ContactSupport,
            logoDesc: "Help with subsciptions and presentation ",
            webURL: "#",
            contactUs: "mailto:info@egopie.co",
        },
        {
            id: 2,
            name: 'Chat With Us',
            image: ChatWithUs,
            listImage: ChatWithUs,
            logoDesc: "Chat & get Quick answers from us or an expert",
            webURL: "#",
            contactUs: "mailto:support@egopie.co",
        },
        {
            id: 3,
            name: 'Contact Sales',
            image: ContactSales,
            listImage: ContactSales,
            logoDesc: "Information on Team Licenses",
            webURL: "#",
            contactUs: "mailto:contactus@egopie.co",
        }
    ];

    const contactRefs = useRef([]);
    const handleButtonClick = (contactId) => {
        const contactRef = contactRefs.current[contactId - 1];
        if (contactRef) {
            contactRef.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const openPositionsRef = useRef(null);
    const parentDivRef = useRef();

    const scrollToTop = () => {
      
            parentDivRef.current.scrollIntoView({
                top: 0,
                behavior: 'smooth'
            });
       
    };
    
    return (
        <>
            <Header />
            <div className="contact.wrapper-section" ref={parentDivRef}>
			<div className="product-list-wrapper  career-wappper-section animation"ref={parentDivRef}>
        <h1 className='text-light text-center'>CONTACT US</h1>
           
            </div>
                <div className='product-list-wrapper'>
                    <div className='container text-white py-5'>
                        <h3 className='product-heading text-center '>Get in touch</h3>
                        <p><h4 className='text-center'>We're happy to hear from you. What's on your mind?</h4><br/></p>
                        <ul className='list-product list-unstyled d-flex justify-content-center'>
                            {contact.map(contact => (
                                <li key={contact.id}>
                                    <div className='product-img position-relative text-center' onClick={() => handleButtonClick(contact.id)}>
                                    
                                        <img src={contact.listImage} alt='product img' className='img-fluid' style={{height:200, width:200}} />
                                   
                                    </div>
                                    <p className='slogan'>{contact.logoDesc}</p>
                                    
                                    <h4 className='text-center'><a href={contact.contactUs}>{contact.name}</a></h4>
									
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <Footer scrollToTopCallback={scrollToTop} />
            </div>
        </>
    );				
};
		
export default Contacts;
