import React, { useState } from 'react'

const OurBussiness = () => {
  const [showDescription, setShowDescription] = useState(false);

    const toggleDescription = () => {
      setShowDescription(!showDescription);
    };
    const [isTextVisible, setTextVisible] = useState(false);

    const toggleText = () => {
      setTextVisible(!isTextVisible);
    };
  return (
  
    <div className='customer-base'> 
	
	 <h1 className='text-light text-center'>Our Business</h1>
	
	<div className="aboutcompany2 pt-5">
	<div className=""> 
      <div  className="row-1 ourBDesc">
        <div className="col-1 text-light">
            <p className="text-center"> EGOPIE was concieved in the United Kingdom in 2019 as an IoT (Internet of Things) and Technology company driven by a purpose to improve the community through her IoT platforms and Technologies. It has since become a registered trademark and a limited liability company, duly incorporated under the Companies and Allied Matters ACT of 2020 in Abuja, Nigeria. The company's affairs are organised and managed by 7 directors including the founder, who is the Chairman of the Board.</p>
        </div>
      </div>
      <div className="row-1 twoRowDesc2">
        <div className="col-1 text-light">
            <p>EGOPIE works in-line with and maintains all regulations pertaining to its businesses. Our web applicaitons collect user data to provide an excellent quality of service. Therefore, we adhere to the Nigerian Data Protection Regulation (NDPR) 2019, to provide the security and privacy of our user's data. Our policies to protect our user's data are in-line with the NDPR data protection framework provided by the National Information Technology Development Agency (NITDA) and other revelant data protection agencies internationally. The Company also works with several foreign and domestic partners who adhere to these regulations as well.</p>
        </div>
        <div className="col-1 text-light">
            <p>The Company currently operates two main line of business while a third line is still in works. It oversees EYES a Road and Traffic security surveillance company, that seeks to rebuild the people's trust in their government to protect their lives and property, by using Artificial Intelligence Surveillance Systems to secure and manage the cities and roads. It also oversees egopie laudromats, a IoT based fully digital self-service laundromat service designed for institutions and communal living areas. In addition Egopie also has stakes in innovative technology companies seeking to impact the society positively. </p>
        </div>
      </div>
	  </div>
	 </div>
	 </div>
	 
	 
	 
	
  )
}

export default OurBussiness
