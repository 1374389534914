import React from "react";
import Index from './components/Index';
import {  Routes, Route, Link } from 'react-router-dom';
import Home from './components/Index';
import ProductCard from './components/ProductCard';
import Contacts from './components/Contacts';
import Career from './components/Career';
import AboutUs from './components/AboutUs';
import './App.css';


function App() {
	
 
  return (
    <Routes>
        <Route path="/" element={<Index />} />
		<Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductCard />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/career" element={<Career />} />
		 <Route path="/contact" element={<Contacts />} />
    </Routes>
	
  );
}

export default App;
	