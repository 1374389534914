import React,{ useRef, useState } from 'react'
import Header from './Header2'
import CareerHero from './subComponents/CareerHero'
import ApplicationForm from './subComponents/ApplicationForm'
import CAREER from "../assets/images/transcareerman.png"
import FlipCards from './subComponents/FlipCards'
import Footer from './Footer'
 

const Career = () => {
    const [JobHeadings, setJobHeadings] = useState(
    [
        {
          title: "Software Engineer",
          roleDetails: {
            responsibilities: [
              "work with various programming technologies",
              "must be able to work in a team",
              "Troubleshoot and repair technical issues.",
              "Ensure compliance with protocols.",
            ],
          },
          flip: false,
        },
        {
          title: "Network Administrator",
          roleDetails: {
            responsibilities: [
              "Understand current networking trends",
              "internal network administration",
              "remote site connectivity",
              "Network policiy administration",
            ],
          },
          flip: false,
        },
        {
          title: "Human Resource Manager",
          roleDetails: {
            responsibilities: [
              "Develop training programs and materials.",
              "Deliver training sessions to staff.",
              "Staff Management",
              "Staff recruitment",
            ],
          },
          flip: false,
        },
        {
          title: "Data Analyst",
          roleDetails: {
            responsibilities: [
              "Data curation",
              "Data managment and control",
              "Data Protection Policy Administration",
              "Data integration",
            ],
          },
          flip: false,
        },
      ]
      );
      
 
       
      
    

    const numberOfCards = JobHeadings.length;
    const [cardStates, setCardStates] = useState(
        new Array(numberOfCards).fill(false)
    );

    const handleCardEnter = (index) => {
        const updatedCards = JobHeadings.map((card, i) =>
            i === index ? { ...card, flip: true } : { ...card, flip: false }
        );
        setCardStates(new Array(numberOfCards).fill(false)); // Reset cardStates
        setJobHeadings(updatedCards); // Update JobHeadings with flipped card
    };

    const handleCardLeave = () => {
        const updatedCards = JobHeadings.map((card) => ({ ...card, flip: false }));
        setCardStates(new Array(numberOfCards).fill(false)); // Reset cardStates
        setJobHeadings(updatedCards); // Update JobHeadings with flipped card
    };

    const openPositionsRef = useRef(null);
    const parentDivRef = useRef();

    const scrollToTop = () => {
      
            parentDivRef.current.scrollIntoView({
                top: 0,
                behavior: 'smooth'
            });
       
    };

    const sectionRefs = [
        useRef(null), // Ref for the first section
        useRef(null), // Ref for the second section
        // Add more refs for additional sections as needed
      ];
      

  return (
    <div>
      <Header/>
      <div className="career-container career-product-wrapper2">
        <div className="product-list-wrapper  career-wappper-section animation"ref={parentDivRef}>
        <h1 className='text-light text-center'>CAREER</h1>
            <CareerHero scrollToOpenPositions={() => openPositionsRef.current.scrollIntoView({ behavior: 'smooth' })}/>
            </div>
            <div className="career-flips career-section " ref={openPositionsRef}>
                <h1 className='text-light text-center'>OPEN POSITIONS</h1>
              <div className='container text-white py-5'>

                        <ul className=' career-flips-style list-unstyled d-flex justify-content-center'>
                      
                 
            {JobHeadings.map((card, index) => (
                                <FlipCards
                                    key={card.id}
                                    isFlipped={card.flip} // Use the flip property from the card object
                                    onMouseEnter={() => handleCardEnter(index)}
                                    onMouseLeave={handleCardLeave}
                                    RoleHeading = {card.title}
                                    roleDetail= {card.roleDetails.responsibilities   }
                                />
                            ))}
                                   </ul>
                    </div>
					<h3 className='text-light text-center'>Please Apply Here:               
                            </h3>
            </div>
             <div className="career-section">
            <ApplicationForm/>

             </div>
       
      <Footer scrollToTopCallback={scrollToTop} />
      </div>
    </div>
  )
}

export default Career
