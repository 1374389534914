import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function ApplicationForm() {
  const [formData, setFormData] = useState({
    picture: "",
    cvInfo: "",
    cvFile: null,
    name: "",
    email: "",
    position: "", // Modified to a select dropdown
    educationLevel: "",
    additionalInfo: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "picture" || name === "cvFile" ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    // Reset the form after submission
    setFormData({
      picture: "",
      cvInfo: "",
      cvFile: null,
      name: "",
      email: "",
      position: "",
      educationLevel: "",
      additionalInfo: "",
    });
  };

  return (
    <div className="career-form">
     
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="picture">
           <Form.Label className="text-light">Upload Your Picture</Form.Label>
          <Form.Control
            type="file"
            name="picture"
            onChange={handleChange}
            accept="image/*"
          />
        </Form.Group>


        <Form.Group controlId="name">
           <Form.Label className="text-light">Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="email">
           <Form.Label className="text-light">Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="position career-section">
           <Form.Label className="text-light">Position to Apply</Form.Label>
          <Form.Control
            as="select"
            name="position"
            value={formData.position}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Software Engineer">Software Engineer</option>
            <option value="Data Analyst">Data Analyst</option>
            <option value="Network Engineer">Network Engineer</option>
			<option value="Human Resource Manager">Human Resource Manager</option>
			<option value="Other">Other</option>
            {/* Add more options as needed */}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="educationLevel">
           <Form.Label className="text-light">Level of Education</Form.Label>
          <Form.Control
            as="select"
            name="educationLevel"
            value={formData.educationLevel}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="High School">High School</option>
            <option value="Bachelor's Degree">Bachelor's Degree</option>
            <option value="Master's Degree">Master's Degree</option>
            <option value="Ph.D.">Ph.D.</option>
          </Form.Control>
        </Form.Group>
		
		<Form.Group controlId="cvFile">
           <Form.Label className="text-light">Upload Your CV</Form.Label>
          <Form.Control
            type="file"
            name="cvFile"
            onChange={handleChange}
            accept=".pdf,.doc,.docx"
          />
        </Form.Group>
		
		<Form.Group controlId="cvInfo">
           <Form.Label className="text-light">Additional Qualifications</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="cvInfo"
            value={formData.cvInfo}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="additionalInfo">
           <Form.Label className="text-light">Cover Letter (300)</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="additionalInfo"
            value={formData.additionalInfo}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary 4" className=" mt-3" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default ApplicationForm;
