import React, { useState } from 'react'
import Basecustomer from "../../assets/images/basecustomer.png";

const OurCustomerBase = () => {
    const [showDescription, setShowDescription] = useState(false);

    const toggleDescription = () => {
      setShowDescription(!showDescription);
    };
    const [isTextVisible, setTextVisible] = useState(false);

    const toggleText = () => {
      setTextVisible(!isTextVisible);
    };
  return (
    <div className='customer-base'> 
        
      <h1 className='text-light text-center'>Our Partners</h1>
          <div className="aboutcompany mb-5 mt-4">
                    <div className="aboutcomp-sub  ">

                    <div className="col-1  w-50 pt-5">
                        <div className="row-1 w-100 aboutsidetext customertext">
                            
                            <p className='text-light'>We work with public and private institutions including the government to be able to drive our vision and mission into the society.
                                
                            </p>
                        </div>
                        <div className="row-1 w-100 aboutsidetext">
                            
                            <p className='text-light'>Our partnerships are structured with the goal to reach as many people as possible, to be able to impact them with our technologies. We are boundless because of our constantly scaling IoT platform. As a result, we use the opportunity to reach out to as many people as we can whereever they are.
                                
                            </p>
                        </div>
                    
                        <div className="row-1 w-100 aboutsidetext">
                           
                            <p className='text-light'> Irrespective of any business of ours, our customers, users or clients engage us in, we have something positive to offer to them all. Therefore, we constantly seek ways to increase our partnerships and customer base that we may impact more people.
                                
                            </p>
                        </div>
                        <div className="customerbasemore">
                       
      <button onClick={toggleText} className="sector-header bg-transparent text-light w-100 d-flex justify-content-between p-2">
        <span>Partners</span>
        <span>{isTextVisible ? "−" : "+"}</span>
        
      </button>
      <div className={`sector-text ${isTextVisible ? "visible" : "yes"}`}>
        <p>
          Some of our Customers and Partners include:
        </p>
        <ul>
          <li>BruteForce</li>
          <li>Rinovate Technologies</li>
          <li>Flutterwave</li>
          <li>Paystack</li>
          <li>Sleek</li>
        </ul>
      </div>
    
                            
                        </div>
                         
                    </div>
                
                    <div className="col-1 w-50 pt-5 ">
                        <div className="aboutsideimg">

                        <img src={Basecustomer} alt='partner img' className='' style={{height:450, width:400}} />
						
                        </div>
                    </div>
                </div>
                    </div>
    </div>
  )
}

export default OurCustomerBase
