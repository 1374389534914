import React, { useEffect, useRef, useState } from 'react';
 
 
import { Link, Element } from 'react-scroll';
import Header from './Header'
import '../App.css'
import AOS from 'aos';
import 'aos/dist/aos.css'
import Footer from './Footer';
import FlipCards from './subComponents/FlipCards';
import Chairman from '../assets/images/salt.png';
import COO from '../assets/images/pedro.png';
import HOP from '../assets/images/hop.png';
import Finance from '../assets/images/ofor.png';
import Goodness from '../assets/images/goodness.png';
import OurBussiness from './subComponents/OurBussiness';
import OurCustomerBase from './subComponents/OurCustomerBase';
import CustomerDetails from './subComponents/CustomerDetails';
import AboutSlider from './subComponents/AboutSlider';
import ContactUs from './subComponents/ContactUs';

const AboutUs = () => {
    
    const openPositionsRef = useRef(null);
    const parentDivRef = useRef();

    const scrollToTop = () => {
      
            parentDivRef.current.scrollIntoView({
                top: 0,
                behavior: 'smooth'
            });
       
    };
   
   

    const userData = [
        {
          id: 1,
          userName: 'Obed Odume',
          position: 'Chairman and CEO',
          company: 'Egopie LLC',
          description:
            'Obed is a Computer Scientist and passionate Network Engineer. He attended Renaissance University where he completed his first degree in computer science with honours. Shortly after, he advanced his studies in the United Kingdom where he completed a masters program in the Internet of Things and Cyber Security with disctinction. He is ever learning and is currently a seasoned techpreneur with vast experience in world of technology and entrepreneurship. He is a firm believer in the use of technology to improve the lives of people- most especially in Africa, and has taken several bold steps to make it a reality.',
          image: Chairman,
        },
        {
          id: 2,
          userName: 'Pedro Onyagu',
          position: 'COO',
          company: 'Egopie LLC',
          description:
            'Pedro is Computer Scientist with a track record of excellence in people and material management within the Tech industry. His prudence and good judgement has led him to champion various tech projects to success. He is motivated towards advancing technology projects and moves with one major goal- to beat each time set to get things done.',
            image: COO,
        },
        {
          id: 3,
          userName: 'Isaiah Miyake',
          position: 'CTO',
          company: 'Egopie LLC',
          description:
            'Isaiah is a seasoned computer scientist and software engineer. He bagged his first degree in computer science at Northumbria University UK where he graduated with honours. He is a passionate technocrat and has had a long standing career in the Tech industy.',
          image:"https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
		{
          id: 3,
          userName: 'Oforbuike Nwodoh',
          position: 'CFO',
          company: 'Egopie LLC',
          description:
            'Oforbuike Nwodoh is a computer scientist turned accountant. He graduated as a Computer Scientist from Caritas University with honours after which he obtained a masters degree in Godefery Okoye University. Prior to his position at Egopie, Ofor worked as a tax officer with the Enugu State Internal Revenue Service. He has a track record of excellence in finance managment and policies.',
          image:Finance,
        },
		{
          id: 3,
          userName: 'Dagogo E. Sepiribo',
          position: 'Head of Partnership',
          company: 'Egopie LLC',
          description:
            'Dagogo Emmanuel Sepiribo is a Civil Engineer who graduated from the Rivers State University, Port Harcourt with honours. He carried on his career as a Civil Engineer up until his position as the Head of Partnership for Egopie. His knack for building relationships has distingued him amongst his peers and set him up to manage the various relationships of the company and its partners ',
          image:HOP,
        },
		{
          id: 3,
          userName: 'Goodness Enoch',
          position: 'Head of Marketing and Strategy',
          company: 'Egopie LLC',
          description:
            'Goodness is a seasoned product manager with a track record of successful product launches. She attended the Ahmadu Bello University Zaria, where she attained her first degree in Mass Communication. She later proceeded to the United Kingdom on further studies and secured a masters degree in marketing. Her unwavering determination has led to a steady growth in the companys integration into the community.',
          image:Goodness,
        },
      ];
// scroll animation 
useEffect(() => {
  AOS.init({duration:2000})
}, [])


     




    return (
        <div>
            <Header />
            <div className="about-wrapper-section2 about-wrapper-section3" >
                <div className='product-list-wrapper2 mb-md-0 'ref={parentDivRef}>
            <h1 className='text-light mb-3 text-center animation'>About Us</h1>
                    
                    <br/><AboutSlider/>
                </div>
                <div className="aboutcompany about-section mb-4 mt-5 pt-5"  >
                    <div className="aboutcomp-sub">

                    <div className="col-1  w-50">
                        <div className="row-1 w-100 aboutsidetext">
                            <h3 className='text-light pt-5'>Vision</h3>
                            <p className='text-light mt-3'>Our Vision is to become the biggest technology company in Nigeria to form a fertile foundation for the innovation of mordern technological advancment in Africa and the world.
                                
                            </p>
                        </div>
                        <div className="row-1 w-100 aboutsidetext">
                            <h3 className='text-light'>Mission</h3>
                            <p className='text-light mt-3'>Our Mission is to build the Egopie brand to be associated with all positive impact made to the lives of many, by improving the way they live and interact in the society through the use of technology. 
                                
                            </p>
                        </div>
                        <div className="row-1 w-100 aboutsidetext">
                            <h3 className='text-light'>Core Values</h3>
                            <p className='text-light mt-3'>Our Core Values are Integrity, Diligence, Responsibilty, Loyalty, Corporate Governace and Standard Service. 
                                
                            </p>
                        </div>
                         
                    </div>
                    <div className="col-1 w-50   pt-5 ">
                        <div className="aboutsideimg">

                        <img src="https://images.pexels.com/photos/2295433/pexels-photo-2295433.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                        </div>
                    </div>
                </div>
                    </div>
                    <div className="aboutDescription about-section" >
                    <OurBussiness/>
                   
                    </div>
                    <div className="aboutDescription about-section" >
                    
                    <OurCustomerBase/>
                    </div>
                    <div className="customeraboutinfo mt-5  mb-3 about-section">
                        <div className="customer-section">
                        <h1 className='text-light mb-3 text-center ' >Principal Persons</h1>
                        {userData.map((user) => (
        <CustomerDetails key={user.id} user={user} />
      ))}
                        </div>
                        
                    </div>
                    <div className="about-contactus about-section">
                        <ContactUs/>
                    </div>
                    <Footer scrollToTopCallback={scrollToTop} />
            </div>
        </div>
    )
}

export default AboutUs
