import React from 'react'
import Header from './Header';
import Home from './Home';

const Index = () => {
  return (
    <div>
      <div className='banner-wrapper'>
        <Header />
        <Home />
      </div>
    </div>
  )
}

export default Index