import React from 'react';
import { useInView } from "react-intersection-observer";

const CustomerDetails = ({ user }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once when the element enters the viewport
  });
  return (
    <>
    
     <div className="customer-details-section">
      <div className={`row-1 mt-3 mb-3 customerDetails w-100 d-flex justify-content-center align-items-center customer-details ${inView ? "fade-in" : ""}`}   ref={ref}>
        <div className="col-1 d-flex w-50 p-4 text-light">
          <div className="cDetailImage">
            <img src={user.image} alt="" />
          </div>
          <div className="cDetailsbox p-4">
            <div className="cName text-light">
              <p>Name: <span>{user.userName}</span></p>
            </div>
            <div className="cposition">
              <p>Position: <span>{user.position}</span></p>
            </div>
            <div className="cCompany">
              <p>Company: <span>{user.company}</span></p>
            </div>
          </div>
        </div>
        <div className="col-1 w-50 profile_details p-4 text-light">
          <div className="customerDescrpt">
            <p>{user.description}</p>
          </div>
        </div>
      </div>
      </div>
      
    </>
  );
};

export default CustomerDetails;
 