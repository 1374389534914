import React from "react";
import CardFlip from "react-card-flip";

const FlipCards = ({ isFlipped, onMouseEnter, onMouseLeave ,RoleHeading,roleDetail}) => {
  return (
    <div
      className="card-container career-section flip-card-container w-10 d-flex bg-yellow"
      onMouseLeave={onMouseLeave}
    >
      <CardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        className="d-flex bg-red bg-primary"
      >
        {/* Front of the card (image) */}
        <div
          className="card d-flex flex-1 font-card-careers justify-content-between background-yellow "
          onMouseEnter={onMouseEnter}
        >
          <div className="flip-front-head mb-5  d-flex align-items-center justify-content-center">
            <h3 className="">{RoleHeading}</h3>
          </div>
            <div className="flip-front-main-button   ">
              <button type="button" className="p-1 px-4">DETAIL</button>
            </div>
          <div className="flip-fornt-details  ">
            <p className="text-uppercase   text-center">Click for more details</p>
          </div>
         
        </div>

        {/* Back of the card (image and text) */}
        <div className="card w-100 h-100 bg-red">
        <div className="flip-front-head    d-flex align-items-center justify-content-center">
            <h3 className="">Positive Overview</h3>
          </div>
          <div className="flip-back-details pt-3   text-light">
            <p>Role Description:</p>
            <ul className="text-light  ">
            {roleDetail.map((responsibility, index) => (
                    <li key={index}>{responsibility}</li>
                  ))}
             
             
            </ul>
          </div>
           
        </div>
      </CardFlip>
    </div>
  );
};

export default FlipCards;

 
