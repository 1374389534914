import React, {useState} from "react";
import Logo from '../assets/images/egopie-icon.png'
import { Link } from "react-router-dom";


const Header2 = () => {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const handleIconClick = () => {
    // Toggle the state variable when the icon is clicked
    setIsDivVisible(!isDivVisible);
  };

  return (
    <header className="header2">
      <div className="container">
        <div className="navigation">
          <Link to={"/"}><img src={Logo} alt="" /></Link>

          <div role="button" onClick={handleIconClick} className="toggle-icon position-relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none">
              <g id="SVGRepo_bgCarrier" strokeWidth="0" />
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
              <g id="SVGRepo_iconCarrier"> <path d="M5 7H19" stroke="#2abcf2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> <path d="M5 12L19 12" stroke="#2abcf2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> <path d="M5 17L19 17" stroke="#2abcf2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" /> </g>
            </svg>
            <ul className={isDivVisible ? 'menu-list visible' : 'menu-list hidden'}>
			<li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/products">Get Started</Link></li>
			  <li><Link to="/career">Career</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header2;