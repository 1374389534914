import React from 'react';
import Hero from "../../assets/images/transcareerman.png"

const CareerHero = ({ scrollToOpenPositions }) => {
  return (
    <div>
          <div className="aboutcompany mb-5 mt-4 career-top" >
                    <div className="aboutcomp-sub  career-top-sub">

                    <div className="col-1 career-top-desc ">
                        <div className="row-1 w-100aboutsidetext customertext">
                            
                            <h1 className='career-head-text'>
                                JOIN OUR TEAM
                            </h1>
                        </div>
                        {/* <div className="row-1 w-100 aboutsidetext customertext">
                            
                            <h5 className=' career-head-subheading'>
                            Be Part of Empowering Visionary Security.
                            </h5>
                        </div> */}
                         
                    
                        <div className="row-1 w-100 aboutsidetext career-top-desc">
                           
                            <h6 className='text-light '>At Egopie we are changing the way the world works. To do this better everyday, we are constantly looking for the best minds to join our team of decent hard workers and innovators. Are you interested in building your career with us? Then check out our job openings. 
                                
                            </h6>
                        </div>
                        <div className="row-1 w-100 aboutsidetext career-top-desc">
                           
                        <div class="button-container">
    <button onClick={scrollToOpenPositions}  className="view-jobs-button">View Job Openings
    <span class="arrow"></span>
    </button>
  </div>
                        </div>
                      
                         
                    </div>
                
                    <div className="col-1  career-top-img">
                        <div className="careersideimg">

						<img src={Hero}	alt='product img' className='' style={{height:400, width:370}} />
					   
                        </div>
                    </div>
                </div>
                    </div>
     
    </div>
  )
}

export default CareerHero
    