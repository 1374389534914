 
import React, { useState ,useEffect} from "react";
import { CSSTransition } from "react-transition-group";


 
const images = [
  {
    id: 1,
    imgUrl: "https://images.pexels.com/photos/1054397/pexels-photo-1054397.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    heading: "Technology",
    detail:"Technology is the bedrock of our company, it drives the products and services we offer to the community."
  },
  
  {
    id: 2,
    imgUrl: "https://images.pexels.com/photos/209728/pexels-photo-209728.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    heading: "Community",
    detail:"Our company thrives on a vibrant and supportive community. Therefore, improving the community is our goal."
  },
  {
    id: 3,
    imgUrl: "https://i.postimg.cc/Xq84Cc1N/techimage.jpg",
    heading: "Service",
    detail:"Our commitment to exceptional service is the cornerstone of our company's success, delivering value that goes beyond expectations."
  },
];
const AboutSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Auto advance to the next slide every 4 seconds (4000 milliseconds)
  useEffect(() => {
    const interval = setInterval(nextSlide, 4000);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval);
  }, []);

    
   


  return (
    <>
      <div className="slider-container">
      <button onClick={prevSlide} className="arrow-button left">
        &#60;
      </button>
      <CSSTransition
        in={true}
        appear={true}
        timeout={8000}
        classNames="fade"
        key={images[currentIndex].id}
      >
        <div
          className="slider-item"
          style={{
            backgroundImage: `url(${images[currentIndex].imgUrl})`,
          }}
        >
            <div className="slider-text p-5 ">
          <h2 className="text-light slider-head">{images[currentIndex].heading}</h2>
          <p className="text-light w-50">{images[currentIndex].detail}</p>
          </div>
        </div>
      </CSSTransition>
      <button onClick={nextSlide} className="arrow-button right">
        &#62;
      </button>
    </div>

    </>
  )
}

export default AboutSlider
