import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/egopie-logo.png"
const Home = () => {
  
  return (
    <section className="banner">
      <div className="container">
        <div className="animation text-center align-items-center">
          <img className="egopie-logo img-fluid mb-sm-5 mb-4" src={Logo} alt="" />
          { /*<h1 className="logo-text">Egopie</h1>*/}
          <h1>Improving the Community with Technology</h1>
          <Link to={"/products"}>ENTER</Link>
        </div>
      </div>
    </section>
  );
};

export default Home;
